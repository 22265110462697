export const staticCards = [
  {
    id: 1,
    name: "Portal de pago",
    icon: "ion:wallet-outline",
    link: "https://sigloservicios.com/ConsultasWeb/selecciontasa.aspx?33678663609",
  },
  {
    id: 2,
    name: "Guia de Trámites",
    icon: "mdi:form-outline",
    link: "/tramites",
  },
  {
    id: 3,
    name: "Reclamos",
    icon: "hugeicons:complaint",
    link: null,
  },
  {
    id: 5,
    name: "Obras Privadas",
    icon: "mdi:worker-outline",
    link: "/obras-privadas",
  },
];

export const inSite = [
  {
    id: 6,
    name: "Transporte Público",
    icon: "ion:bus",
    link: "/transporte",
  },
  {
    id: 7,
    name: "Centro de salud",
    icon: "iconoir:healthcare",
    link: "/salud",
  },
  {
    id: 8,
    name: "Servicios al vecino",
    icon: "ph:users-three-bold",
    link: "/vecino",
  },
  {
    id: 4,
    name: "Turismo",
    icon: "la:map-marked",
    link: "/turismo",
  },
  {
    id: 9,
    name: "Boletín Oficial",
    icon: "pepicons-pop:bulletin-notice",
    link: "/boletin",
  },
];

import {
  Button,
  Divider,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import imagen from "../../assets/455607851_1457509868210182_1195636238485826266_n.jpg";
import foot from "../../assets/banner4.png";

import { useEffect } from "react";

// const pdfData = [
//   {
//     title: "Ordenanzas 782 - 790",
//     fileId: "1EaGnkTmvZ7UwALMBHyk28xsVpoHPenr3",
//   },
//   {
//     title: "Ordenanzas 791 - 801",
//     fileId: "1PoxiF8wTpvUfsaiB1_xF2sv-Ge7_F4Wm",
//   },
//   {
//     title: "Decretos 1 - 19",
//     fileId: "1RMCtkoB2tNgyrcAO1_dc8g-uJN2RHRCv",
//   },
//   {
//     title: "Decretos 20 - 31",
//     fileId: "1B_pI-e-RoKpb1mHSlVm5hhHyegWvlmOq",
//   },
// ];

const Boletin = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container mt={5} justifyContent={"center"}>
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          height: "400px",
          zIndex: -300,
          backgroundImage: `url(${foot})`, // Usar la imagen importada
          backgroundSize: "cover", // Ajusta cómo se muestra la imagen
          backgroundPosition: "bottom", // Centra la imagen
          backgroundRepeat: "no-repeat", // Evita que la imagen se repita
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{ position: "absolute", bottom: 0, height: "10px" }}
        >
          <Grid item xs={4} sx={{ backgroundColor: "#163E6A" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#F0891F" }} />
          <Grid item xs={4} sx={{ backgroundColor: "#036B39" }} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={8}
        mt={"277px"}
        height={"fit-content"}
        justifyContent={"center"}
      >
        <Typography
          textAlign={"center"}
          fontSize={36}
          fontWeight={600}
          color={"#fff"}
        >
          Boletín Oficial
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent={"center"}
        xs={10}
        mt={10}
        mb={2}
        spacing={3}
      >
        <Grid item xs={12} md={8}>
          <PdfViewer />

          {/* {pdfData.map((pdf, index) => (
            <Grid item xs={12} md={6} key={index}>
              <PdfViewer title={pdf.title} fileId={pdf.fileId} />
            </Grid>
          ))} */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Boletin;

const PdfViewer = ({ title, fileId }) => (
  <Card
    className="w-full"
    sx={{
      borderRadius: 4,
      boxShadow:
        "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    }}
  >
    <Grid container p={2} justifyContent={"space-between"}>
      <Typography gutterBottom variant="h5" color={"#163e6a"}>
        Boletín Oficial
      </Typography>
      <Button
        variant="contained"
        className="bg-primary text-primary-foreground"
        //href={`https://drive.google.com/uc?export=download&id=${fileId}`}
        href="https://drive.google.com/file/d/11lBmq7LT56M52LhYkFhBdaEwEh8Xy1Qu/"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ backgroundColor: "#163e6a" }}
      >
        Descargar PDF
      </Button>
    </Grid>
    <CardContent>
      <iframe
        //src={`https://drive.google.com/file/d/${fileId}/preview`}
        src="https://drive.google.com/file/d/11lBmq7LT56M52LhYkFhBdaEwEh8Xy1Qu/preview"
        width="100%"
        height="600px"
        style={{ border: "none" }}
        allowFullScreen
        title={title}
      />
    </CardContent>
  </Card>
);
